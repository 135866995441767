<template>
  <div class="container">
    <a-page-header :title="title" />
    <div class="main-content">
      <div class="ribbon-wrap ribbon-wrap-1">
        <div class="ribbon-item">
          <div class="item left">
            <div class="timer-picker">
              <span class="ribbon-label">按完成时间段：</span>
              <a-date-picker v-model="startAt" placeholder="开始日期" />
              <span class="ribbon-center-text">至</span>
              <a-date-picker v-model="endAt" placeholder="结束日期" />
            </div>
          </div>
          <div class="item area-cascader">
            <span class="ribbon-label">按地区查询：</span>
            <a-cascader
              :options="cascaderAreaOptions"
              placeholder="选择订单地区"
              expand-trigger="hover"
              :field-names="fieldNames"
              v-model="areaId"
            />
          </div>
          <div class="item right">
            <a-button
              class="search-btn"
              type="primary"
              :disabled="
                (!startAt && !areaId.length) || (!endAt && !areaId.length)
              "
              @click="onSearchs"
            >
              查 询
            </a-button>
            <a-button
              class="reset-btn"
              :disabled="
                (!startAt && !areaId.length) || (!endAt && !areaId.length)
              "
              @click="onReset"
            >
              重 置
            </a-button>
          </div>
        </div>
      </div>
      <div class="ribbon-wrap ribbon-wrap-2">
        <a-radio-group v-model="orderStatu" @change="onStatuRadioChange">
          <a-radio-button v-for="r in orderStatus" :key="r.id" :value="r.statu">
            {{ r.text }}
          </a-radio-button>
        </a-radio-group>
        <a-button icon="download" @click="isModalExport = true">
          批量导出订单
        </a-button>
      </div>
      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1300, y: true }"
        :row-key="(record) => record.orderId"
        :row-selection="rowSelection"
      >
        <template slot="type" slot-scope="record">
          <a-button
            class="type-btn"
            type="link"
            @click="onDeviceOpen(record.orderId, record.type)"
            >{{ record.type }}</a-button
          >
        </template>
        <template slot="status" slot-scope="record">
          {{ $CorderStatusText[record.status] }}
        </template>
        <template slot="name" slot-scope="record">
          {{ record.name }}({{ record.mobile }})
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button type="link">
            <router-link
              :to="{
                path: '/carbeautyDetail',
                query: { id: record.orderId }
              }"
            >
              更多详情
            </router-link>
          </a-button>
          <a-dropdown placement="bottomCenter">
            <a-icon type="ellipsis" />
            <a-menu slot="overlay">
              <!-- <a-menu-item
                :key="0"
                :disabled="record.status > 2 ? true : false"
                @click="ondispatchMasterOpen(index)"
              >
                <span v-if="record.servicerInfo[0].servicerName">转派订单</span>
                <span v-else>指定派单</span>
              </a-menu-item> -->
              <a-menu-item
                :key="1"
                :disabled="record.status > 2"
                @click="onEditBookingAt(record.orderId)"
              >
                修改上门时间
              </a-menu-item>
              <a-menu-item
                :key="2"
                :disabled="record.status > 5"
                @click="onCancelOrder(record.orderId)"
              >
                取消订单
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
        <template slot="expandedRowRender" slot-scope="record">
          <div
            class="ul"
            v-if="
              record.servicerInfo[0].servicerName ||
                record.servicerInfo[0].servicerName
            "
          >
            <div class="li" v-if="record.servicerInfo[0].servicerName">
              <span class="label">师傅姓名：</span>
              <span class="value">
                {{ record.servicerInfo[0].servicerName }}
                ({{ record.servicerInfo[0].smobile }})
              </span>
            </div>
            <div class="li" v-if="record.servicerInfo[0].apprentice">
              <span class="label">学徒姓名：</span>
              <span class="value">
                {{ record.servicerInfo[0].apprentice }}
                ({{ record.servicerInfo[0].appmobile }})
              </span>
            </div>
          </div>
          <div class="ul">
            <div class="li">
              <span class="label">下单用户：</span>
              <span class="value">
                {{ record.servicerInfo[0].name }}
                ({{ record.servicerInfo[0].amobile }})
              </span>
            </div>
          </div>
          <div class="ul">
            <div class="li address">
              <span class="label">预约上门地址：</span>
              <span class="value">{{ record.servicerInfo[0].address }}</span>
            </div>
          </div>
        </template>
        <div class="pagination" slot="footer">
          <div class="export-box">
            <template v-if="selectedRowKeys.length">
              <span class="export-label">已选</span>
              <span class="export-num">{{ selectedRowKeys.length }}</span>
              <span class="export-label">条</span>
              <a-divider type="vertical" />
              <span class="export-label">批量：</span>
              <a-button
                class="export-btn"
                icon="download"
                @click="isModalExportCheck = true"
              >
                导出已选
              </a-button>
            </template>
          </div>
          <a-pagination
            :default-current="pagination.current"
            :total="pagination.total"
            @change="handlePaginationChange"
          />
        </div>
      </my-table>
    </div>

    <a-modal
      title="修改上门时间"
      :visible="modalVisible"
      :confirm-loading="modalConfirmLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
    >
      <a-date-picker show-time placeholder="上门时间" v-model="datePicker" />
    </a-modal>

    <a-modal
      :title="typeModalTitle + '-下单设备'"
      :visible="typeModalVisible"
      :footer="false"
      :width="1044"
      @cancel="handleTypeModalCancel"
    >
      <a-table
        class="device-tabel"
        :columns="columns2"
        :data-source="devices"
        :pagination="false"
        :row-key="(record) => record.id"
        :loading="deviceLoading"
      >
        <template slot="price" slot-scope="records">
          <a-statistic prefix="￥" :value="records.price" />
        </template>
      </a-table>
    </a-modal>

    <a-modal
      :title="'批量导出 - ' + title"
      :visible="isModalExport"
      :footer="false"
      :width="700"
      @cancel="onModalExportCancel"
    >
      <div class="find-order">
        <div class="find">
          <div class="timer-picker">
            <span class="ribbon-label">按完成时间段：</span>
            <a-date-picker v-model="startAt2" placeholder="开始日期" />
            <span class="ribbon-center-text">至</span>
            <a-date-picker v-model="endAt2" placeholder="结束日期" />
          </div>
          <div class="btn-box">
            <a-button
              class="search-btn"
              type="primary"
              :disabled="!startAt2 || !endAt2"
              :loading="findBtnLoading"
              @click="onFindOrder"
            >
              查 询
            </a-button>
            <a-button
              class="reset-btn"
              :disabled="!startAt2 && !endAt2"
              @click="onReset2"
            >
              重 置
            </a-button>
          </div>
        </div>
        <a-alert
          :message="`（${findTime}）时间段内查询的订单，共${findNum}条`"
          type="success"
          show-icon
          v-if="isFindFinish"
        />
        <div class="footer-btn" v-if="isFindFinish">
          <a-button
            class="export-btn"
            type="primary"
            :loading="exportBtnLoading"
            :disabled="!findNum"
            @click="onExport"
          >
            导出
          </a-button>
        </div>
      </div>
    </a-modal>

    <a-modal title="导出已选" :visible="isModalExportCheck">
      <div class="content">
        已选
        {{ selectedRowKeys.length }}
        条数据（提示：数据中的图片、附件、手写签名暂不支持导出）
      </div>
      <template slot="footer">
        <a-button key="back" @click="onModalExportCheckCancel">
          取 消
        </a-button>
        <a-button
          class="search-btn"
          type="primary"
          key="submit"
          :loading="exportBtnLoading"
          @click="onExport"
        >
          导 出
        </a-button>
      </template>
    </a-modal>

    <a-modal
      :title="dispatchMasterData.title"
      :width="816"
      :visible="isDispatchMaster"
      @cancel="ondispatchMasterClose"
    >
      <div class="dispatch-master-title-1">上门信息</div>
      <div class="ul">
        <div class="li">
          <span class="label">预约上门时间：</span>
          <span class="value">
            {{ dispatchMasterData.bookingAt }}
          </span>
        </div>
        <div class="li">
          <span class="label">上门联系人：</span>
          <span class="value">
            {{ dispatchMasterData.name }}
          </span>
        </div>
        <div class="li">
          <span class="label">联系电话：</span>
          <span class="value">
            {{ dispatchMasterData.amobile }}
          </span>
        </div>
      </div>
      <div class="ul-2">
        <div class="li">
          <span class="label">预约上门地址：</span>
          <span class="value">
            {{ dispatchMasterData.address }}
          </span>
        </div>
      </div>
      <a-divider />
      <div class="dispatch-master-title-2">接单师傅</div>
      <div class="ul">
        <div class="li" v-if="dispatchMasterData.servicerName">
          <span class="label">当前接单师傅：</span>
          <span class="value">{{ dispatchMasterData.servicerName }}</span>
        </div>
        <div class="li dispatch-master">
          <div class="select-box">
            <span v-if="dispatchMasterData.servicerName">转派新师傅：</span>
            <span v-else>指定一名师傅：</span>
            <a-select
              placeholder="选择师傅"
              v-model="dispatchMasterData.dispatchMasterId"
            >
              <a-select-option
                v-for="item in dispatchMasters"
                :key="item.id"
                :value="item.id"
              >
                {{ item.realName }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button key="back" @click="ondispatchMasterClose">
          取 消
        </a-button>
        <a-button
          class="search-btn"
          type="primary"
          key="submit"
          :loading="dispatchMasterBtnLoading"
          @click="onDispatchMaster"
          v-if="dispatchMasterData.dispatchType === 1"
        >
          确认派单
        </a-button>
        <a-button
          class="search-btn"
          type="danger"
          key="submit"
          :loading="dispatchMasterBtnLoading"
          @click="onDispatchMaster"
          v-else
        >
          确认转派
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
const dispatchMasterData = {
  dispatchType: 1, // 1：指定；2：转派
  title: '',
  orderId: '',
  name: '',
  amobile: '',
  address: '',
  bookingAt: '',
  areaId: '',
  servicerName: '',
  dispatchMasterId: undefined
}
let servingInfoLength = 0
let editId = null // 修改上门时间的订单id
const renderContent = (text, record, index) => {
  const obj = {
    children: text,
    attrs: {}
  }
  if (index === servingInfoLength) {
    obj.attrs.colSpan = 0
  }
  return obj
}
export default {
  data() {
    return {
      title: '',
      dateValue: [],
      startAt: '',
      endAt: '',
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      cascaderAreaOptions: [],
      areaId: [],
      dateValue2: [],
      startAt2: '',
      endAt2: '',
      orderStatu: 0,
      orderStatus: [
        {
          id: 1,
          text: '全部',
          statu: 0
        },
        {
          id: 2,
          text: '待接单',
          statu: [1]
        },
        {
          id: 3,
          text: '进行中',
          statu: [2, 3, 4]
        },
        {
          id: 4,
          text: '待验收',
          statu: [5]
        },
        {
          id: 5,
          text: '已完成',
          statu: [6]
        },
        {
          id: 6,
          text: '已关闭',
          statu: [7, 8]
        }
      ],
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '订单号',
          width: 200,
          dataIndex: 'outTradeNo'
        },
        {
          title: '服务项目',
          width: 200,
          scopedSlots: {
            customRender: 'type'
          }
        },
        {
          title: '购买用户',
          width: 200,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '购买时间',
          width: 200,
          dataIndex: 'payAt'
        },
        {
          title: '订单状态',
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '操作',
          width: 150,
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading: true,
      pagination: {
        current: 1,
        total: 0
      },
      datePicker: '',
      modalVisible: false,
      modalConfirmLoading: false,
      typeModalTitle: '',
      typeModalVisible: false,
      typeModalConfirmLoading: false,
      columns2: [
        {
          title: '序号',
          width: 72,
          dataIndex: 'sort',
          customRender: (text, record, index) => {
            if (index < servingInfoLength) {
              return index + 1
            }
            return {
              children: <span style="font-weight: bold;">{text}</span>,
              attrs: {
                colSpan: 4
              }
            }
          }
        },
        {
          title: '服务项目',
          dataIndex: 'serverName',
          width: 196,
          customRender: renderContent
        },
        {
          title: '家电名称',
          dataIndex: 'Info',
          width: 180,
          customRender: renderContent
        },
        {
          title: '家电属性',
          dataIndex: 'subtitle',
          customRender: renderContent
        },
        {
          title: '家电数量（件）',
          dataIndex: 'quantity',
          width: 144
        },
        {
          title: '金额',
          width: 100,
          scopedSlots: { customRender: 'price' }
        }
      ],
      devices: [],
      deviceLoading: true,
      selectedRowKeys: [],
      isModalExport: false,
      findTime: '',
      findNum: 0,
      findBtnLoading: false,
      isFindFinish: false,
      exportBtnLoading: false,
      isModalExportCheck: false,
      dispatchMasters: [],
      isDispatchMaster: false,
      dispatchMasterBtnLoading: false,
      dispatchMasterData: JSON.parse(JSON.stringify(dispatchMasterData))
    }
  },
  computed: {
    ...mapState(['$CorderStatusText']),
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (key) => {
          this.selectedRowKeys = key
        }
      }
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getCorderItem()
    this.getArea()
  },
  methods: {
    async getArea() {
      // 获取区域列表
      const area = await this.$axios.getArea()
      console.log("area",area.nested)
      this.cascaderAreaOptions = area.nested
    },
    onSearchs() {
      // 高级搜索
      this.loading = true
      this.pagination.current = 1
      this.getCorderItem()
    },
    onReset() {
      // 重置时间
      this.startAt = ''
      this.endAt = ''
      this.areaId = []
      this.getCorderItem()
    },
    onStatuRadioChange() {
      // 监听订单状态选择
      this.loading = true
      this.pagination.current = 1
      this.getCorderItem()
    },
    getCorderItem() {
      // 获取单品订单列表
      const data = {
        page: this.pagination.current,
        limit: 10
      }
      if (this.startAt) {
        data.date = [
          {
            startAt: moment(this.startAt).format('YYYY-MM-DD') + ' 00:00:00',
            endAt: moment(this.endAt).format('YYYY-MM-DD') + ' 23:59:59'
          }
        ]
      }
      if (this.orderStatu) {
        data.status = this.orderStatu
      }
      if (this.areaId.length) {
        data.areaId = this.areaId[this.areaId.length - 1]
      }
      this.$axios.getCorderItem(data).then((res) => {
        const d = res.data.data
        this.dataSource = d.list
        this.pagination.total = d.count
        this.loading = false
      })
    },
    handlePaginationChange(e) {
      this.loading = true
      this.pagination.current = e
      this.getCorderItem()
    },
    onEditBookingAt(id) {
      // 修改上门时间
      editId = id
      this.modalVisible = true
    },
    handleModalCancel() {
      // 取消修改上门时间
      editId = null
      this.datePicker = ''
      this.modalVisible = false
      this.modalConfirmLoading = false
    },
    handleModalOk() {
      // 确定修改上门时间
      this.modalConfirmLoading = true
      const data = {
        orderId: editId,
        bookingAt: moment(this.datePicker).format('YYYY-MM-DD HH:mm:ss')
      }
      this.$axios
        .changeOrderItemTime(data)
        .then(() => {
          this.modalConfirmLoading = false
          this.$message.success('操作成功')
          this.handleModalCancel()
          this.getCorderItem()
        })
        .catch(() => {
          this.handleModalCancel()
        })
    },
    handleTypeModalCancel() {
      // 关闭下单设备-弹窗
      this.typeModalVisible = false
      this.deviceLoading = true
      this.devices = []
    },
    onDeviceOpen(id, name) {
      // 查看单设备-弹窗
      this.typeModalTitle = name
      this.typeModalVisible = true
      this.getCorderItemDevice(id)
    },
    getCorderItemDevice(id) {
      // 获取单品订单设备列表
      const data = {
        orderId: id
      }
      this.$axios.getCorderItemDevice(data).then((res) => {
        const d = res.data.data
        servingInfoLength = d.data.length
        let quantity = 0
        d.data.forEach((e) => {
          quantity += Number(e.quantity)
        })
        const row = {
          id: 0,
          sort: '总计',
          servingName: '',
          infoName: '',
          subtitle: '',
          quantity: quantity,
          price: d.priceSum
        }
        d.data.push(row)
        this.devices = d.data
        this.deviceLoading = false
      })
    },
    onCancelOrder(id) {
      // 取消订单
      this.$confirm({
        title: '确定取消订单?',
        onOk: () => {
          const data = {
            orderId: id
          }
          this.$axios.cancelOrderItem(data).then(() => {
            this.$message.success('操作成功')
            this.getCorderItem()
          })
        }
      })
    },
    onModalExportCancel() {
      // 取消-批量导出
      this.isModalExport = false
      this.exportBtnLoading = false
      this.isFindFinish = false
      this.onReset2()
    },
    onFindOrder() {
      // 查询订单导出数量
      this.findBtnLoading = true
      const startAt = moment(this.startAt2).format('YYYY-MM-DD') + ' 00:00:00'
      const endAt = moment(this.endAt2).format('YYYY-MM-DD') + ' 23:59:59'
      const t = `${startAt.substr(0, 10)} 至 ${endAt.substr(5, 5)}`
      this.findTime = t
      const data = {
        type: 1,
        startAt: startAt,
        endAt: endAt,
         btype:"card",
      }
      this.$axios.findOrderExport(data).then((res) => {
        this.findNum = Number(res.data.data)
        this.findBtnLoading = false
        this.isFindFinish = true
      })
    },
    onReset2() {
      // 重置时间
      this.startAt2 = ''
      this.endAt2 = ''
      this.isFindFinish = false
    },
    onExport() {
      // 批量导出
      this.exportBtnLoading = true
      const data = {
        type: 1,
         btype:"card",
      }
      if (this.startAt2) {
        data.startAt = moment(this.startAt2).format('YYYY-MM-DD') + ' 00:00:00'
        data.endAt = moment(this.endAt2).format('YYYY-MM-DD') + ' 23:59:59'
      }
      if (this.selectedRowKeys.length) {
        data.id = this.selectedRowKeys
      }
      this.$axios.orderExport(data).then((res) => {
        this.exportBtnLoading = false
        this.$message.success('数据导出Excel成功')
        if (this.startAt2) {
          this.onModalExportCancel()
        }
        if (this.selectedRowKeys.length) {
          this.onModalExportCheckCancel()
        }
        window.open(res.data.data)
      })
    },
    onModalExportCheckCancel() {
      // 取消-导出已选
      this.selectedRowKeys = []
      this.isModalExportCheck = false
      this.exportBtnLoading = false
    },
    ondispatchMasterOpen(i) {
      // 打开转派/指定派单
      const item = this.dataSource[i]
      const user = item.servicerInfo[0]
      let title = ''
      let dispatchType = 1
      if (user.servicerName) {
        // 转派
        dispatchType = 2
        title = `转派师傅 - ${item.type}（订单号：${item.outTradeNo}）`
      } else {
        // 指定
        title = `指定派单 - ${item.type}（订单号：${item.outTradeNo}）`
      }
      this.dispatchMasterData = {
        orderId: item.orderId,
        title: title,
        dispatchType: dispatchType,
        name: user.name,
        amobile: user.amobile,
        address: user.address,
        bookingAt: item.bookingAt,
        areaId: item.areaId,
        servicerName: user.servicerName,
        dispatchMasterId: undefined
      }

      this.getDispatchMaster()
      this.isDispatchMaster = true
    },
    ondispatchMasterClose() {
      // 关闭转派/指定派单
      this.dispatchMasterData = JSON.parse(JSON.stringify(dispatchMasterData))
      this.isDispatchMaster = false
    },
    getDispatchMaster() {
      // 可转派师傅
      const data = {
        areaId: this.dispatchMasterData.areaId
      }
      this.$axios.getDispatchMaster(data).then((res) => {
        this.dispatchMasters = res.data.data
      })
    },
    onDispatchMaster() {
      // 转派/指定派单
      if (!this.dispatchMasterData.dispatchMasterId) {
        this.$message.error('请先选择师傅')
        return
      }
      const data = {
        orderId: this.dispatchMasterData.orderId,
        servicerId: this.dispatchMasterData.dispatchMasterId
      }
      this.dispatchMasterBtnLoading = true
      this.$axios
        .dispatchMaster(data)
        .then(() => {
          this.dispatchMasterBtnLoading = false
          this.$message.success('操作成功')
          this.ondispatchMasterClose()
          this.getCorderItem()
        })
        .catch(() => {
          this.dispatchMasterBtnLoading = false
        })
    }
  }
}
</script>

<style scoped>
.ribbon-wrap {
  box-sizing: content-box;
}

.ribbon-wrap-1 {
  padding: 24px;
  border-radius: 2px;
  margin-bottom: 16px;
}

.ribbon-wrap-2 {
  padding: 24px 24px 0 24px;
  margin-bottom: 0;
  border-radius: 2px 2px 0 0;
}

.ribbon-item {
  display: flex;
  justify-content: space-between;
  line-height: 32px;
}

.ribbon-item .item {
  display: flex;
}

.ribbon-label {
  color: #252525;
  margin-right: 10px;
}

.ribbon-search-label {
  margin-right: 0;
}

.ribbon-item .cancel-btn {
  margin-left: 16px;
}

.ribbon-item .right {
  display: flex;
}

.ribbon-item .timer-picker,
.ribbon-item .area-cascader {
  margin-right: 24px;
}

.ribbon-item .reset-btn {
  margin-left: 16px;
}

.timer-picker {
  display: flex;
  align-items: center;
}

.ribbon-center-text {
  margin: 0 8px;
}

.ant-calendar-picker {
  width: 160px;
}

.type-btn {
  width: 100%;
  padding: 0;
  text-align: left;
}

.type-btn >>> span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ul {
  display: flex;
  align-items: center;
}

.ul:not(:last-child) {
  margin-bottom: 16px;
}

.ul .li {
  width: 240px;
}

.ul .li:not(:last-child) {
  margin-right: 24px;
}

.ul .address {
  width: auto;
}

.device-tabel {
  border: 1px solid #d9d9d9;
}

.find-order .find {
  display: flex;
  justify-content: space-between;
}

.find-order .ant-alert {
  margin: 16px 0 24px 0;
}

.find-order .reset-btn {
  margin-left: 8px;
}

.find-order .footer-btn {
  overflow: hidden;
}

.find-order .export-btn {
  float: right;
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.export-box {
  display: flex;
  align-items: center;
}

.export-box .export-label,
.export-box .export-num {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.export-box .export-label {
  color: #333333;
}

.export-box .export-num {
  color: #177ddc;
  margin: 0 8px;
}

.export-box .export-btn {
  margin-left: 12px;
}

.dispatch-master-title-1 {
  font-size: 14px;
  line-height: 22px;
  color: #333;
  font-weight: bold;
  margin-bottom: 16px;
}

.dispatch-master-title-2 {
  font-size: 16px;
  line-height: 24px;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.ul .dispatch-master {
  width: auto;
}

.select-box {
  display: flex;
  align-items: center;
}

.select-box .ant-select {
  width: 240px;
}
</style>
